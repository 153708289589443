import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import GlobalStyles from "../components/layout/GlobalStyles"
import Header1 from "../components/layout/textFormatingComponents/Header1"
import MetaDetails from "../components/seoComponents/MetaDetails"
import { Breadcrumbs } from "../components/layout/Breadcrumb"
import styled from "styled-components"
import { device } from "../utils/mediaQueries"
import BlogFeaturedImage from "../components/images/BlogFeaturedImage"

const MainArea = styled.div`
  flex: 8;
`

const LeftSideBar = styled.div`
  flex: 2;
  margin: 0px auto;
`
const RightSideBar = styled.div`
  flex: 1;
`

const Container = styled.div`
  padding: 2rem 6rem;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  background-color: #f2f2f2;
  @media ${device.tablet} {
    flex-direction: column;
    margin: 2px;
    padding: 1rem;
    border-radius: 0px;
  }
`

const BlogPostContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 2rem;
`

const BlogPost = styled.div`
  border: 1px solid #e5e5e5;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: white;
`

const BlogPostTitle = styled.h3`
  font-weight: 800;
  font-size: 1rem;
  text-align: center;
  padding: 1rem;
`

const Excerpt = styled.p`
  font-weight: 300;
  font-size: 0.7rem;
  text-align: center;
  padding: 1rem;
`

const BlogLink = styled(Link)`
  text-decoration: none;
  color: black;
`

const HeadingContainer = styled.div`
  margin: 0rem;
`
const Template = ({ data }) => {
  const [position, setPosition] = useState(false)

  const listenScrollEvent = e => {
    if (window.scrollY > 390) {
      setPosition(true)
    }
    if (window.scrollY < 390) {
      setPosition(false)
    }
  }

  useEffect(() => {
    // Update the document title using the browser API
    window.addEventListener("scroll", listenScrollEvent)
  })

  const blogPosts = data.allMarkdownRemark.edges.map((post, i) => {
    return (
      <BlogLink to={post.node.frontmatter.path}>
        <BlogPost>
          <BlogFeaturedImage imgName={post.node.frontmatter.featuredImage} />
          <BlogPostTitle>{post.node.frontmatter.title}</BlogPostTitle>
          <Excerpt>{post.node.excerpt}</Excerpt>
        </BlogPost>
      </BlogLink>
    )
  })

  return (
    <GlobalStyles blog={true}>
      <MetaDetails
        title={data.markdownRemark.frontmatter.metaTitle}
        desc={data.markdownRemark.frontmatter.metaDesc}
      />
      <HeadingContainer>
        <Header1>{data.markdownRemark.frontmatter.title}</Header1>
        <Breadcrumbs />
      </HeadingContainer>
      <Container>
        <LeftSideBar></LeftSideBar>
        <MainArea>
          <BlogPostContainer>{blogPosts}</BlogPostContainer>
        </MainArea>
        <RightSideBar></RightSideBar>
      </Container>
    </GlobalStyles>
  )
}

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        metaDesc
        metaTitle
      }
    }
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "blogPost" } } }) {
      edges {
        node {
          frontmatter {
            featuredImage
            title
            path
          }
          excerpt
        }
      }
    }
  }
`

export default Template
